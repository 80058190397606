
  import { mapGetters } from 'vuex'
  import IconBase from '../components/IconBase.vue'
  import IconLogoAeqNoir from '../components/icons/IconLogoAeqNoir.vue'

  export default {
    name: `HomePage`,
    options: {
      auth: false
    },
    components: {
      IconBase,
      IconLogoAeqNoir
    },
    beforeRouteLeave(to, from, next) {
      // IMPROVE with drawer.js middleware
      this.$store.commit(`drawerMenu/openDrawer`)
      next()
    },
    data() {
      return {
        stateOfService: {
          colorClass: `gray`,
          message: `Not available`,
          value: null
        }
      }
    },
    head() {
      return {
        title: `Home`
      }
    },
    computed: {
      doesRedirectUrlExist() {
        return this?.$store?.state?.redirect
      },
      ...mapGetters({
        userIsClient: `permissions/userIsClient`,
        userIsCandidate: `permissions/userIsCandidate`,
        userIsCollab: `permissions/userIsCollab`,
        userIsConsultonly: `permissions/userIsConsultonly`,
        userHasCurrentCompany: `companies/userHasCurrentCompany`
        // ...
      })
    },
    async created() {
      console.log(this.$config.public)
      await this.$auth.fetchUser()
    },
    mounted() {
      this.getStateOfService()
      if (this.$auth.loggedIn && !!this.$auth?.user) {
        // Double check current default company
        if (!!this?.$store?.state?.redirect) {
          this.redirectToRouteState()
        } else if (this.userIsClient) {
          this.push(`/client`)
        } else if (this.userIsCandidate) {
          if (this.$auth.user?.candidate_screenings?.length === 1) {
            this.push(`/screenings/${this.$auth.user?.candidate_screenings}`)
          } else {
            this.push(`/candidate`)
          }
        } else if (this.userIsConsultonly) {
          this.push(`/client`)
        } else if (this.userIsCollab) {
          this.push(`/my-settings`)
          // this.push('/dashboard')
        } else {
          this.errorMessage()
        }
      }
      this.$store.commit(`external/clearToken`)
    },
    methods: {
      async getStateOfService() {
        const res = await this.$api.stateOfService.get()
        this.stateOfService.value = res.data.toLowerCase()
        this.stateOfService.colorClass = this.getStateOfServiceColor(this.stateOfService.value)
      },
      getStateOfServiceColor(status) {
        return {
          available: `green`,
          with_issue: `yellow`,
          unavailable: `red`
        }[status]
      },
      redirectToRouteState() {
        this.$store.dispatch(`redirect`)
      },
      push(url) {
        this.$router.push({ path: url })
      },
      errorMessage() {
        this.$flashMessage.error({
          title: this.$t(`messages.error`),
          message: !this.userHasCurrentCompany
            ? this.$t(`messages.no_suitable_permission`)
            : this.$t(`messages.account_error`),
          time: 0
        })
      }
    }
  }
